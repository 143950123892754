import React from 'react'


const Tea = () => {
  return (
    <div className='flex items-center mt-[2vh] gap-10 max-sm:grid max-sm:grid-rows max-md:grid max-md:grid-rows max-lg:grid max-lg:grid-rows max-xl:ml-[0px] mx-[10%] max-sm:mx-[0%] max-sm:gap-[0]'>
        <div className=' ml-[80px] mb-[100px] flex items-center gap-4 max-sm:ml-[0px] max-sm:mr-[0px]  max-sm:mb-[20px] max-md:ml-[40px] max-md:mb-[30px] '>
            
         <img src={'assets/teapage.png'} alt='teapage' width={500} height={500} className='max-sm:ml-[0px] max-lg:ml-[20px]'/>
          
        </div>
        <div className='w-[60vh] max-sm:w-[37vh] max-sm:ml-[40px] max-md:ml-[90px] max-lg:ml-[160px]'style={{color:'#67290a'}} >
            <h1 className='text-4xl font-semibold max-sm:text-3xl' >The perfect substitute for sugar</h1>
            <p className='mt-[50px]'>
            Adding jaggery to your tea not only enhances 
            its sweetness but also brings ahost of
            health benefits. Unlike refined sugar,
            jaggery is rich in vitamins.
            </p>
            <p className='mt-[50px]'>
            Adding jaggery to your tea not only 
            enhances its sweetness but also brings
            host of health benefits. Unlike refined
            sugar, jaggery is rich in vitamins.
            </p>
            <p className='mt-[50px]'>
            Adding jaggery to your tea not only enhances
            its sweetness but also brings  host of 
            health benefits. Unlike refined sugar, 
            jaggery is rich in vitamins.
            </p>
        </div>
      
    </div>
  )
}

export default Tea
