'use client'
import React, { useState } from 'react';
import { FaArrowRight } from "react-icons/fa"
import  './Process.css'

const Benefits = () => {
    const [isHovered, setIsHovered] = useState(false);

    // Define image paths for normal and hover states
    const normalImage = './assets/b2.jpeg';
    const hoverImage = './assets/bb2.jpeg';

  return (
    <div className='flex items-center gap-6 mt-[10vh]  max-sm:grid max-sm:grid-rows max-sm:mx-[10%] max-sm:mt-[0vh] max-md:grid max-md:grid-rows max-lg:grid max-lg:grid-rows max-xl:grid max-xl:grid-rows max-md:mx-[10%] mx-[2%]'>
        <div className=''>
            <div className='mt-[0px] mb-[100px] max-sm:mb-[50px] max-md:mb-[50px] max-lg:mb-[50px] '>
                <h1 className='text-[8vh] font-semibold pt-[80px] pl-[60px] pb-[0] max-sm:ml-[8%] max-sm:text-[5vh]' style={{color:'#D3BCAC'}}>
                    Benefits
                </h1>
                <div className='flex items-center gap-6  max-sm:grid max-sm:grid-rows max-sm:mx-[10%] max-lg:ml-[20px]'>
                <div className='cardcontainer w-[280px] h-[250px] rounded-xl ml-[100px] mt-[100px] max-sm:ml-[0px] max-sm:mt-[30px] max-md:ml-[45px]' style={{backgroundColor:'#FFF0DF'}}>
                    <p className='pt-[40px] pl-[10px] pr-[10px] font-md ' style={{color:'#67290a'}}>
                    Chemical free jaggery 
                    retains its natural flavour
                    & purity since it’s not 
                    processed using synthetic 
                    chemicals or additives.
                    </p>
                    <div  className='flex items-center justify-center  rotate-[-40deg] text-2xl ml-[200px] mt-[20px]' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a', width:'50px',height:'50px'}}>
                    <FaArrowRight />

                    </div>

                </div>
                <div className='cardcontainer w-[280px] h-[250px] rounded-xl max-sm:ml-[0px] max-sm:mt-[50px] ' style={{backgroundColor:'#FFF0DF'}}>
                    <p className='pt-[40px] pl-[10px] pr-[10px] font-md ' style={{color:'#67290a'}}>
                    Chemical free jaggery 
                    retains its natural flavour
                    & purity since it’s not 
                    processed using synthetic 
                    chemicals or additives.
                    </p>
                    <div  className='flex items-center justify-center  rotate-[-40deg] text-2xl ml-[200px] mt-[20px]' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a', width:'50px',height:'50px'}}>
                    <FaArrowRight />

                    </div>

                </div>
            </div>
            <div className='flex items-center gap-6 max-sm:grid max-sm:grid-rows max-sm:mx-[10%] max-lg:ml-[20px]'>
                <div className='cardcontainer w-[280px] h-[250px] rounded-xl mt-[80px] ml-[100px] max-sm:ml-[0px] max-md:ml-[45px]' style={{backgroundColor:'#FFF0DF'}}>
                    <p className='pt-[40px] pl-[10px] pr-[10px] font-md ' style={{color:'#67290a'}}>
                    Chemical free jaggery 
                    retains its natural flavour
                    & purity since it’s not 
                    processed using synthetic 
                    chemicals or additives.
                    </p>
                    <div  className='flex items-center justify-center  rotate-[-40deg] text-2xl ml-[200px] mt-[20px]' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a', width:'50px',height:'50px'}}>
                    <FaArrowRight />

                    </div>

                </div>
                <div className='cardcontainer w-[280px] h-[250px] rounded-xl max-sm:ml-[0px] max-sm:mt-[50px]' style={{backgroundColor:'#FFF0DF'}}>
                    <p className='pt-[40px] pl-[10px] pr-[10px] font-md ' style={{color:'#67290a'}}>
                    Chemical free jaggery 
                    retains its natural flavour
                    & purity since it’s not 
                    processed using synthetic 
                    chemicals or additives.
                    </p>
                    <div  className='flex items-center justify-center  rotate-[-40deg] text-2xl ml-[200px] mt-[20px]' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a', width:'50px',height:'50px'}}>
                    <FaArrowRight />

                    </div>

                </div>
            </div>
            </div>

           
         
        </div>
 
    <div 
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
         >
        {/* Use ternary operator to switch between normal and hover images */}
        <img
        src={isHovered ? hoverImage : normalImage}
        alt="Image"
       
        className='ml-[90px] mt-[290px] rounded-md sm:mt-[50px] max-md:ml-[35px] max-sm:mt-[100px] max-sm:ml-[6%] w-[600px] h-[600px] max-sm:w-[300px] max-sm:h-[330px] max-sm:mt-[50px] max-lg:ml-[115px]'
        />
    </div>

      
    </div>
  )
}

export default Benefits
