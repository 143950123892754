import React from 'react'


const Hero = () => {
  return (
    <div className='w-full h-full flex items-center gap-[50px] mt-[5vh] max-sm:grid max-sm:grid-rows max-md:grid max-md:grid-rows max-lg:grid max-lg:grid-rows' >
        <div className='w-[50vw] h-[90vh] max-sm:h-[40vh] ' style={{backgroundColor:'#F4E7DC '}}>
            <h1 className='text-[11vh] ml-[110px] mt-[70px] font-bold  max-sm:text-[5vh] max-sm:mt-[0px]' style={{color:'#67290A'}}>
                Naturally yours  <span style={{color:'#C95400'}}>Chemical</span> Free Jaggery
            </h1>
            

            
          
        </div>
        <div className='w-[50vw] h-[90vh] max-sm:h-[40vh] max-sm:mx-[7%] max-sm:mt-[7%] ' >
            <div className='w-[50vw] h-[60vh]  max-sm:h-[60vh] max-sm:w-[40vh]'>
              <img src={'/assets/home.jpg'} alt='homepage' width={500} height={500} className='ml-[200px] mt-[110px]  max-sm:ml-[0px] max-sm:mt-[0px]'/>
            

            </div>
          

        </div>

    </div>
  )
}

export default Hero
