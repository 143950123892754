import React from 'react'
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
    
    const Header = () => {
        const [showMenu, setShowMenu] = React.useState(false)
        const handleMenu = () => {
            setShowMenu(!showMenu)
        }
        
      return (
        <>
        
        <header  className={`flex gap-[30vh] mx-[0%] pl-[2.5%] items-center h-[10vh] w-[100%] font-medium shadow-md  `}>
            {
                showMenu ||
                <div>
                <img className='w-[120px] h-[100px] ' src='../assets/logolotus.png' alt='logo'/>
            </div>
            }
            {
                showMenu && 
              
                    <div style={{backgroundColor:'#f4e7da'}} className='fixed bottom-0 w-full h-full md:hidden'>
                    <RxCross2 style={{backgroundColor:'#F4E7DC',color:'#67290A', borderColor:'#67290a'}} onClick={handleMenu} className='mt-[6%] ml-[85%]  rounded-full text-4xl md:hidden'/>
                    <ul  className='flex flex-col text-4xl mt-[10%] ml-[10%]  ' style={{color:'#67290a'}}>
                        <li className='mt-[8%]'><a onClick={handleMenu}  href='#home'>Home</a></li>
                        <li className='mt-[8%]'><a onClick={handleMenu} href='#about'>About</a></li>
                        <li className='mt-[8%]'><a onClick={handleMenu} href='#contact'>Contact</a></li> 
                    </ul>
                    </div>
                   
              
            }
            <nav className='w-[50%] hidden md:block ml-[35%]'  >
                <ul className='flex gap-[20vh]  ' style={{color:'#67290a'}}>
                    <li className='hover:text-orange-400 '><a href='#home'>Home</a></li>
                    <li className='hover:text-orange-400 '><a href='#about'>About</a></li>
                    <li className='hover:text-orange-400 '><a href='#contact'>Contact</a></li> 
                </ul>
            </nav>
            {
                showMenu ||
                <RxHamburgerMenu onClick={handleMenu} className='text-4xl md:hidden mr-[5%]' style={{color:'#67290a'}}/>
            }
           
    
        </header>
        </>
      )
    }
    
    export default Header