import React from 'react';
import './style.css'
import Nav from './components/Nav';
import Hero from './components/Hero';
import Quality from './components/Quality';
import Benefits from './components/Benefits';
import About from './components/About';
import Tea from './components/Tea';
import Bake from './components/Bake';
import Process from './components/Process';
import Category from './components/Category';
import Footer from './components/Footer';
import About1 from './components/About1'




const App = () => {
  return (
    <div  className='overflow-x-hidden' style={{backgroundColor:'#f4e7dc'}}>
      <Nav />
      <Hero />
      <Quality />
      <Category />
      <Benefits />
      <About />
      <About1 />
      <Tea />
      <Bake />
      <Process />
      <Footer />
     
   
      
    </div>
  )
}

export default App
