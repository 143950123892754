import React from 'react'
import { FaInstagram } from "react-icons/fa"
import { BsTwitterX } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className='mt-[0vh] flex items-center gap-10 max-sm:grid max-sm:grid-rows '>
       <div className='max-sm:hidden md-hidden lg-hidden flex items-center gap-[67vh]' style={{color:'#67260a'}}>
               <img  src={'assets/frame.png'} alt='png' width={500} height={500}/>
               <div className='mt-[10%]'>
               <p className='font-semibold text-3xl my-[5%]'>
               Contact Us
               </p>
               <div className='flex items-center gap-[2vh] my-[7%] text-2xl font-md'>
              <FaLocationDot />
              <p className=''>
              Pune,India
              </p>
            </div>
           <div className='flex items-center gap-[2vh] text-2xl my-[7%] font-md'>
          <FaPhoneAlt />
      
          <p>
          +91 9657525353
          </p>
       
          </div>
          <div className='flex items-center gap-[2vh] text-2xl my-[7%] font-md'>
          <MdEmail />
          <p  >
            vighnahartaagroproducts@gmail.com
          </p>
          </div>

                
               </div>


        </div>
       
  
       

  









    <div className='xl:hidden md:hidden max-sm:mb-[1vh] max-md:mb-[0vh] max-md:mr-[5vh] max-lg:mb-[10vh] ' style={{color:'#67260a'}}>
    <div className='max-sm:ml-[5vh] max-sm:mt-[4vh] max-md:ml-[5vh] max-md:mt-[2vh]  max-lg:ml-[5vh] max-lg:mt-[2vh]' >
          <p className='max-sm:text-xl  max-sm:py-3 max-sm:font-semibold max-md:text-xl max-md:font-md max-md:py-3 max-md:font-semibold max-md:border-b-2 max-md:border-brown-300   max-lg:text-xl  max-lg:py-3 max-lg:font-semibold max-lg:border-b-2 max-lg:border-brown-300 '>
          contact Us
          </p>
      <div className='max-sm:flex max-sm:items-center max-sm:gap-4 max-sm:text-s  max-md:flex max-md:items-center max-md:gap-4 max-md:text-s max-md:border-b-2 max-md:border-brown-300   max-lg:flex max-lg:items-center max-lg:gap-4 max-lg:text-s max-lg:border-b-2 max-lg:border-brown-300'>
         <FaLocationDot />
         <p className='max-sm:text-s max-sm:font-md max-sm:py-3 max-md:text-s max-md:font-md max-md:py-3   max-lg:text-s max-lg:font-md max-lg:py-3'>
         Pune,India
          </p>
      </div>
    
    <div className=' max-sm:flex max-sm:items-center max-sm:gap-4 max-md:flex max-md:items-center max-md:gap-4 max-md:border-b-2 max-md:border-grey-300  max-lg:flex max-lg:items-center max-lg:gap-4 max-lg:border-b-2 max-lg:border-brown-300'>
          <FaPhoneAlt />
      
          <p className='max-sm:text-s max-sm:font-md max-sm:py-3 max-md:text-s max-md:font-md max-md:py-3  max-lg:text-s max-lg:font-md max-lg:py-3'>
          +91 9657525353
          </p>
       
    </div>
    <div className='max-sm:flex max-sm:items-center max-sm:gap-4 max-md:flex max-md:items-center max-md:gap-4 max-lg:flex max-lg:items-center max-lg:gap-4'>
          <MdEmail />
          <p  className='max-sm:text-s max-sm:font-md max-sm:py-3 max-md:text-s max-md:font-md max-md:py-3  max-lg:text-s max-lg:font-md max-lg:py-3' >
           vighnahartaagroproduct@gmail.com
          </p>
    </div>
  </div>
</div>
<div className='  lg:hidden md:hidden sm:hidden mb-[19vh] ml-[8vh] max-sm:ml-[1vh] max-sm:mb-[2vh] max-sm:mt-[0vh] max-sm:font-semibold max-md:mb-[0vh] max-md:mt-[2vh] max-lg:mb-[0vh] max-lg:mt-[2vh]' style={{color:'#67260a'}}> 
          <p className='font-md text-xl pb-[2vh] ml-[5vh] py-3'>Lotus jaggery</p>
          <div className='flex items-center gap-4 ml-[5vh]'>
          <div  className='cardcontainer flex items-center justify-center  shadow-lg text-2xl  mt-[20px] ' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a', width:'50px',height:'50px'}}>
                <FaInstagram />
                </div>
                <div  className='cardcontainer flex items-center justify-center  shadow-lg text-2xl  mt-[20px]' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a', width:'50px',height:'50px'}}>
                <BsTwitterX />
                </div>
                <div  className='cardcontainer flex items-center justify-center  shadow-lg text-2xl mt-[20px] ' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a', width:'50px',height:'50px'}}>
                <FaFacebook />
                </div>
             
      </div>
    </div>

      
  </div>
  )
}

export default Footer
