import React from 'react'
import './Process.css'

const Process = () => {
  return (
    <div>
        <div className='max-sm:mt-[3vh] max-sm:mx-[4%] mx-[3%]'>
            <h1 className='text-7xl  ml-[120px] font-bold max-sm:ml-[0px] max-sm:text-4xl max-lg:ml-[70px] mx-[9%]' style={{color:'#d3bcac'}}> The Process</h1>
            <div>
                <div className='flex items-center gap-6 w-[90vh] ml-[120px] mt-[80px] max-sm:ml-[45px] max-sm:w-[35vh] max-sm:grid max-sm:grid-rows max-sm:mt-[40px] max-md:ml-[40px] max-md:w-[80vh] max-lg:ml-[60px] max-lg:mt-[50px]'>
                <button className='w-[50px] h-[50px] px-5 flex items-center justify-center mb-[100px] font-bold max-sm:mb-[0px] max-md:mb-[0px] max-lg:mb-[35px]' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a'}}>
                    1
                </button>
                <p style={{color:'#67290a'}} className='font-md text-[20px] max-sm:text-[15px] max-md:text-[15px] max-lg:text-[15px]'>
                Our chemical-free jaggery is meticulously crafted using 
                time-honored methods that prioritize purity and quality.
                it all begins with the careful selection of mature and 
                healthy sugarcane , hand-harvested by skilled farmers
                dedicated to preserving traditional practices . 

                </p>

                </div>
                <div className='mb-[30px] mt-[40px] max-sm:mb-[10px] max-sm:w-[360px] max-sm:h-[400px] max-sm:overflow-hidden max-sm:rounded-md '>
                <img src={'assets/farmer.png'} alt='farmer' width={500} height={500} className='cardcontainer ml-[800px] rounded-md max-sm:ml-[52px] max-md:ml-[95px] max-lg:ml-[150px] max-xl:ml-[200px]'/>

                </div>
                
               

            </div>
            <div>
                <div className='flex items-center gap-6 w-[90vh] ml-[600px] mt-[80px]  max-sm:ml-[45px] max-sm:w-[35vh] max-sm:grid max-sm:grid-rows max-sm:mt-[0px] max-md:ml-[40px] max-md:w-[80vh]  max-lg:ml-[60px] max-lg:mt-[100px] max-xl:ml-[120px]'>
                <button className='w-[50px] h-[50px] px-5 flex items-center justify-center mb-[100px] font-bold  max-sm:mb-[0px] max-md:mb-[0px] max-lg:mb-[35px]' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a'}}>
                    2
                </button>
                <p style={{color:'#67290a'}} className='font-md text-[20px] max-sm:text-[15px] max-lg:text-[15px]'>
                Once harvested , the sugarcane undergoes a natural
                extraction process, where its juice is carefully pressed
                 and filtered to remove any impurities. This 
                pure juice is then simmered over a low flame in large shallow pans,
                 allowing it to slowly thicken and develop its rich flavor profile.
                  Through out the boiling process.

                </p>

                </div>
                <div className=' mb-[80px] max-sm:w-[360px] max-sm:h-[400px] max-sm:mb-[30px] max-sm:overflow-hidden max-sm:rounded-md '>
                <img src={'assets/p3.png'} alt='farmer' width={500} height={500} className='cardcontainer ml-[120px] rounded-md mt-[80px] max-sm:mt-[40px] max-sm:ml-[52px] max-md:ml-[90px] max-lg:ml-[150px] max-xl:ml-[200px]'/>

                </div>
                
               

            </div>
            
            <div>
                <div className='flex items-center gap-6 w-[90vh]  ml-[120px] mt-[80px] max-sm:ml-[45px] max-sm:w-[35vh] max-sm:grid max-sm:grid-rows max-sm:mt-[0px] max-md:ml-[40px] max-md:w-[80vh]  max-lg:ml-[60px] max-lg:mt-[100px]'>
                <button className='w-[50px] h-[50px] px-5 flex items-center justify-center mb-[100px] font-bold  max-sm:mb-[0px] max-md:mb-[0px]max-lg:mb-[35px] ' style={{borderRadius:'50%', backgroundColor:'#D3BCAC',color:'#67290a'}}>
                    3
                </button>
                <p style={{color:'#67290a'}} className='font-md text-[20px] max-sm:text-[12px] max-lg:text-[15px]'>
                Once the Jaggery reaches the perfect consistency, it is carefully molded and left to cool preserving its natural 
                sweetness and nutritional benefits . Our commitment to 
                chemical-free production reflects our dedication to offering 
                you a wholesome and authentic sweetener , straight from 
                nature’s bounty to your table.
                </p>

                </div>
                <div className='max-sm:w-[350px] max-sm:h-[400px] max-sm:overflow-hidden max-sm:rounded-md' >
                <img src={'assets/p9.png'} alt='process3' width={500} height={500} className='cardcontainer ml-[800px] rounded-md mt-[80px]  max-sm:ml-[52px] max-sm:mt-[60px] max-md:ml-[90px] max-lg:ml-[150px] max-xl:ml-[200px]' />

                </div>
                
               


                
               

            </div>
            
        </div>
      
    </div>
  )
}

export default Process
