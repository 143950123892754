import React from 'react'
import  './Process.css'

const Category = () => {
  return (
    <div className=' flex items-center gap-6 max-sm:grid max-sm:grid-rows max-sm:mx-[10%] max-md:grid max-md:grid-rows max-md:ml-[190px] max-lg:grid max-lg:grid-rows max-lg:ml-[250px] max-xl:grid max-xl:grid-rows max-xl:ml-[400px]  mx-[8%]'>
        <div>
            <h1 className='text-[8vh] font-semibold pl-[0px] pt-[100px] max-sm:pl-[0px] max-sm:mt-[0px] max-sm:pt-[0px] max-sm:text-[5vh] max-sm:ml-[50px]' style={{color:'#D3BCAC'}}>Category</h1>
            <div className='cardcontainer w-[37vh] h-[70vh] rounded-md mt-[100px] shadow-xl max-sm:mt-[20px] max-sm:w-[33vh] max-sm:ml-[15px] ' style={{backgroundColor:'#E9DACD'}}>
                <div >
                <img  src={'assets/pc2.png'} alt='category' width={600} height={600} className='pt-[80px]'/>
                <div>
                    
                 <p className='text-2xl font-semibold ml-[70px] pt-[110px]' style={{color:'#67290a'}}>
                  Lotus jaggery <br></br>
                 Nuggets
                </p>
                </div>
                </div>
            </div>
        </div>
        <div className='cardcontainer w-[37vh] h-[70vh] rounded-md mt-[100px] shadow-xl max-sm:w-[33vh] max-sm:ml-[20px] max-lg:mt-[50px]' style={{backgroundColor:'#e9dacd'}}>
                <div >
                <div>
                    
                    <p className='text-2xl font-semibold ml-[70px] pt-[40px]' style={{color:'#67290a'}}>
                     Lotus jaggery <br></br>
                    Cubes
                   </p>
                   </div>
                <img  src={'/assets/pc2.png'} alt='category' width={600} height={600} className='pt-[60px]'/>
               
                </div>
        </div>
        <div className='cardcontainer w-[37vh] h-[70vh] rounded-md mt-[300px] shadow-xl max-sm:mt-[100px] max-sm:w-[33vh] max-sm:ml-[20px] max-lg:mt-[50px]' style={{backgroundColor:'#E9DACD'}}>
                <div >
                <img  src={'assets/pc2.png'} alt='category' width={600} height={600} className='pt-[80px]'/>
                <div>
                    
                 <p className='text-2xl font-semibold ml-[70px] pt-[110px]'style={{color:'#67290a'}}>
                  Lotus jaggery <br></br>
                 Powder
                </p>
                </div>
                </div>
        </div>
        <div className='cardcontainer w-[37vh] h-[70vh] rounded-md mt-[100px] shadow-xl max-sm:w-[33vh] max-sm:ml-[20px] max-lg:mt-[50px]' style={{backgroundColor:'#E9DACD'}}>
                <div >
                   <div>
                    <p className='text-2xl font-semibold ml-[70px] pt-[40px]'style={{color:'#67290a'}}>
                     Lotus jaggery <br></br>
                    
                   </p>
                   </div>
                <img  src={'assets/pc2.png'} alt='category' width={600} height={600} className='pt-[60px] '/>
              
                </div>
        </div>
      
    </div>
  )
}

export default Category
