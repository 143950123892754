import React from 'react'

const Quality = () => {
  return (
    
        <div className='w-full h-full flex mx-[2%]  max-sm:grid max-sm:grid-rows max-md:grid max-md:grid-rows max-lg:grid max-lg:grid-rows max-sm:mx-[7%] ' >
        <div className='w-[50vw] h-[90vh] max-sm:w-[39vh] max-sm:h-[75vh] max-md:w-[600px] max-lg:w-[700px]' style={{backgroundColor:'#F4E7DC '}}>
            <div className='flex items-center gap-6 ml-[100px] mt-[50px] max-sm:ml-[18px] max-md:ml-[70px] max-lg:ml-[170px] '>
                <button style={{backgroundColor:'#D3BCAC', borderRadius:'50%', color:'#67290A'}} className='w-[54px] h-[54px] font-bold'>1</button>
                <h1 className='font-semibold text-3xl max-sm:text-2xl' style={{color:'#67290a'}}>
                    100% chemical Free 
                </h1>
            </div>
            <p className='ml-[180px] mt-[10px] max-sm:ml-[25px] max-md:ml-[80px] max-sm:text-[15px] ' style={{color:'#67290a'}}>
            Our chemical free jaggery is crafted with care,
            ensuring you savor the wholesome essence of
            unadulterated sweetness.

            </p>

            <div className='flex items-center gap-6 ml-[100px] mt-[50px] max-sm:ml-[18px] max-md:ml-[70px] max-lg:ml-[170px]'>
                <button style={{backgroundColor:'#D3BCAC', borderRadius:'50%', color:'#67290A'}} className='w-[54px] h-[54px] font-bold'>2</button>
                <h1 className='font-semibold text-3xl max-sm:text-2xl' style={{color:'#67290a'}}>
                    Natural Sweetener 
                </h1>
            </div>
            <p className='ml-[180px] mt-[10px] max-sm:ml-[25px]  max-md:ml-[80px] max-sm:text-[15px]' style={{color:'#67290a'}}>
            Jaggery is a natural alternative to refined sugar.
            It is made without the use of chemicals and retains
            more nutrients compared to sugar.
             It provides a sweet flavor
            to foods and beverages.

            </p>

            <div className='flex items-center gap-6 ml-[100px] mt-[50px]  max-sm:ml-[18px]  max-md:ml-[70px] max-lg:ml-[170px]'>
                <button style={{backgroundColor:'#D3BCAC', borderRadius:'50%', color:'#67290A'}} className='w-[54px] h-[54px] font-bold'>3</button>
                <h1 className='font-semibold text-3xl max-sm:text-2xl' style={{color:'#67290a'}}>
                    Rich in Nutrients
                </h1>
            </div>
            <p className='ml-[180px] mt-[10px] max-sm:ml-[25px]  max-md:ml-[80px] max-sm:text-[15px]' style={{color:'#67290a'}}>
            Jaggery contains essential minerals like iron,
            magnesium, and potassium.It also provides small
            amounts of vitamins and antioxidants.The presence
            of these nutrients makes jaggery a healthier<br></br>
            option compared to refined sugar.

            </p>
           
           
           
            
          
        </div>
        <div className='w-[50vw] h-[90vh] max-sm:w-[80vw] max-sm:h-[50vh] max-sm:mb-[10vh] ' >
            <div className='w-[50vw] max-sm:w-[80vw] max-md:w-[90vw] '>
                <img src={'assets/hero.png'} alt='lady' width={500} height={500} className='ml-[140px] mt-[0px] rounded-md max-sm:ml-[10px] max-md:ml-[95px] max-lg:ml-[250px] max-xl:ml-[50px] max-xl:mt-[40px]'/>
           

            </div>
          

        </div>

    </div>
      
    
  )
}

export default Quality
