import React from 'react'


const About1 = () => {
  return (
    <div className='flex items-center ml-[150px] max-sm:grid  max-sm:grid-rows max-sm:ml-[7%] max-sm:mt-[2vh] max-md:grid max-md:grid-rows max-md:ml-[70px] max-lg:grid max-lg:grid-rows max-lg:ml-[30px] max-lg:mt-[2vh] max-xl:grid max-xl:grid-rows max-xl:ml-[150px] max-sm:gap-[0]'>
        <div className='lotus rounded-xl mt-[50px] max-sm:w-[350px] max-sm:ml-[40px] max-md:w-[500px] max-md:ml-[20px] max-lg:ml-[140px] '>
        <video controls width="80%" height="auto" autoPlay className='rounded-md shadow-md'>
          <source src="/assets/lotusvd.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>   
        </div>
        <div className='ml-[0px] max-sm:ml-[40px] max-md:ml-[0px] mr-[8%]'>
            <div className='flex items-center gap-10 max-sm:grid max-sm:grid-rows'>
                <div className='flex items-center '>
                    <div className='cardcontainer w-[250px] h-[250px] ml-[100px] mt-[100px] flex items-center max-sm:ml-[17px] max-sm:mt-[100px] max-md:ml-[0px]' style={{backgroundColor:' #E9DACD ', borderRadius:'50%'}}>
                 
                    <img src={'/assets/og.png'} alt='mg' width={200} height={200} className='ml-[25px] mt-[10px]'/>
                 
                  

                     </div>

               
                 </div>
           
                <div className='cardcontainer w-[250px] h-[250px] mt-[100px] max-sm:ml-[17px] max-sm:mt-[15px]' style={{backgroundColor:' #E9DACD', borderRadius:'50%'}}>
                <img src={'assets/ch.png'} alt='mg' width={200} height={200} className='ml-[25px] mt-[10px]'/>
                 
                  

                </div>

               
           
               
              
            </div>
            <div className='cardcontainer w-[250px] h-[250px] rounded-xl ml-[230px] mt-[50px] flex items-center max-sm:ml-[17px] max-md:ml-[130px]' style={{backgroundColor:'  #E9DACD', borderRadius:'50%'}}>
                <img src={'/assets/mg.png'} alt='mg' className='ml-[20px] w-[200px] h-[200x]'/>
                 
                  

            </div>
        
        </div>
       
       

               
         
      
    </div>
  )
}

export default About1
