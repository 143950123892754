'use client'
import React, { useState } from 'react';
import  './Process.css'

const About = () => {
  const [isHovered, setIsHovered] = useState(false);

  // Define image paths for normal and hover states
  const normalImage = './assets/about.jpeg';
  const hoverImage = './assets/cube.jpeg ';
  return (
    <div className='flex items-center gap-8  max-sm:grid max-sm:grid-rows max-sm:mx-[7%] max-sm:mt-[3vh] max-md:grid max-md:grid-rows max-lg:grid max-lg:grid-rows max-xl:grid max-xl:grid-rows md:mx-[10%] mx-[0%]'>
        <div className='w-[60vh]   max-sm:ml-[0px] max-sm:w-[37vh] max-md:ml-[50px]'>
            <h1 className='text-[8vh] font-semibold pt-[80px] pl-[110px] pb-[0] max-sm:pl-[70px] max-sm:text-[5vh]' style={{color:'#D3BCAC'}}>About us</h1>
            <p className='pt-[40px] pl-[10px] pr-[0px] font-md ml-[60px] max-sm:ml-[20px] max-sm:pl-[0px]' style={{color:'#67290a'}}>
            Established in 2014 amidst the vibrant cityscape
            of Pune,our jaggery selling brand embodies a 
            rich tradition of purity and quality. From 
            humble beginnings, we embarked on a journey 
            to bring the wholesome sweetness of jaggery
            to homes across the region and beyond. Rooted
            in our commitment to preserving age-old techniques and sourcing
            the finest ingredients, each batch of our jaggery reflects
            our dedication to excellence. Through meticulous
            craftsmanship <br></br>and unwavering integrity, 
            we have garnered the trust of our customers,
            becoming synonymous with authenticity
            and taste. With every bite of our premium
            jaggery, we invite you to savor the essence
            of Pune's heritage and the natural goodness
            of our land.
            </p>
        </div>
     
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Use ternary operator to switch between normal and hover images */}
      <img
        src={isHovered ? hoverImage : normalImage}
        alt="Image"
        className='cardcontainer ml-[150px] mt-[70px] rounded-md max-md:ml-[90px] max-md:mt-[20px] w-[500px] h-[500px] max-sm:w-[300px] max-sm:h-[300px] max-sm:ml-[10%] max-sm:mt-[30px] max-lg:ml-[170px]'
      />
    </div>
      
    </div>
  )
}

export default About
