import React from 'react'


const Bake = () => {
  return (
    <div className='flex items-center gap-10 mt-[2vh] max-sm:grid max-sm:grid-rows  max-md:grid max-md:grid-rows max-lg:grid max-lg:grid-rows max-xl:ml-[90px] mx-[10%] max-sm:mx-[3%] max-sm:mt-[4vh] max-sm:gap-[0]'>
        <div className=' ml-[80px] mb-[50px]  flex items-center gap-4 max-sm:ml-[25px] max-sm:mr-[0px] max-md:ml-[20px] max-xl:ml-[0px] max-xl:w-[400px] max-xl:h-[400px] '>
            
         <img src={'assets/bake.png'} alt='teapage' className='w-[500px] h-[500px] max-sm:h-[450px] '/>
          
        </div>
        <div className='w-[60vh] max-sm:w-[37vh] max-sm:ml-[40px] max-md:ml-[50px] max-lg:ml-[120px] max-xl:ml-[0px]'style={{color:'#67290a'}} >
            <h1 className='text-4xl font-semibold max-sm:text-3xl' >Bake with jaggery bliss</h1>
            <p className='mt-[50px]'>
            Baking with jaggery instead of refined sugar 
            adds natural sweetness to your baked goods
            while providing some minerals and antioxidants,
            making your treats a healthier option.
            </p>
            <p className='mt-[50px]'>
            Jaggery imparts a unique and rich flavor to
            baked goods, enhancing their taste and aroma,
            giving them a distinct character that complements
            various recipes.
            </p>
            <p className='mt-[50px]'>
            Jaggery helps retain moisture in baked items, 
            resulting in softer and more moist textures 
            compared to using granulated sugar, making 
            your baked goods more enjoyable and flavorful.
            </p>
        </div>
      
    </div>
  )
}

export default Bake
